<template>
  <div class="myAdvice">
    <v-input
      type="textarea"
      placeholder="请输入意见"
      :maxlength="300"
      v-model="form.content"
    ></v-input>
    <div class="footer" @click="submitAdvice">提交</div>
  </div>
</template>

<script>
import { findFeedbackPageUrl, saveOrUpdateFeedbackUrl } from "./api";
export default {
  name: "myAdvice",
  components: {},
  props: {},
  data() {
    return {
      form: {
        content: "",
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    async submitAdvice() {
      let params = {};
      if (!this.form.content) {
        this.$toast({ message: "请填写意见", duration: 2000 });
        return;
      }
      params = {
        content: this.form.content,
        insertUser: this.userId,
        houseId: this.roomId,
      };
      let res = await this.$axios.post(`${saveOrUpdateFeedbackUrl}`, params);

      if (res.code === 200) {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped lang="less">
.myAdvice {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  box-sizing: border-box;
  padding: 40px 32px;
  .footer {
    text-align: center;
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
    border-radius: 10px;
    font-size: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
  }
}
</style>
